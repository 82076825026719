import { Button, Form, FormGroup, Row, Col } from "react-bootstrap";
import Head from "./Head";
import './style.css';
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from './DataContext';
import { useState } from "react";

export const CardOption = () => {
const {getUser} = useContext(DataContext);
const [amount, setAmount]  = useState();
    
const navigate = useNavigate();
const user = getUser('user');
    useEffect(() => {
        if(!user){
            document.title = "UXTECH | Card Payment Option";
            navigate('/login?login');
        
        }
        }, [])

    return (
        <div className=''>
        <Row  style={{ margin:'auto'}} className=" row ">
             <h2 className="text-center my-3"> Secure Payment GateWay </h2>
            <Col className="col-lg-4 col-md-6 col-sm-8 col-xs-10 align-items-center" style={{ margin:'auto', backgroundColor: 'whitesmoke'}}>
                <Form 
                action="https://checkout.marasoftpay.live/"
                target="_blank"
                method="post"
                >
                <input type="hidden" name="public_key" value={"MSFT_live_KA0BG6X7MNLI2BQPWRA4FTHHRUYSZLSO"} />

                    <input type="hidden" name="merchant_ref" value={user.data.email} />

                    <input type="hidden" name="email_address" value={user.data.email} />
                    <input type="hidden" name="name" value={user.data.name} />

                    <input type="hidden" name="phone_number" value={user.data.phone} />

                    <input type="hidden" name="request_type" value="live" />

                    <input type="hidden" name="description" value="fund wallet" />

                    <input type="hidden" name="currency" value="NGN" />

                    <input type="hidden" name="redirect_url" value={'uxtech.ng/dashboad'} />

                    <input type="hidden" name="user_bear_charge" value="yes" />
                    <FormGroup>
                        <Form.Label> Amount </Form.Label>
                        <Form.Control type="number" 
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        name="amount"
                        placeholder="Enter amount you want to fund to your wallet"
                        required
                        >
                        </Form.Control>
                    </FormGroup>
                    <Button type="submit" variant="dark" className="w-100" style={{ marginTop: '20px' }}> Continue </Button>
                    
                    <small className=" text-muted" 
                    style={{ color:'red', justifyItems: 'center'}}> 
                    50 flat rate charge! 
                    </small>
                </Form>
            </Col>
        </Row>
        </div>
    )
}