import { Button, Form, FormGroup, Row, Col, Toast } from "react-bootstrap";
import Head from "./Head";
import './style.css';
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from './DataContext';
import { useState } from "react";
import { CardOption } from "./CardOption";
import { Transfer } from "./Transfer";
import { userData } from "./API";

const Fund = () => {
const {getUser, deleteUser} = useContext(DataContext);
const [active, setActive] = useState('transfer');
const [status, setStatus] = useState(true);
const [message, setMessage] = useState('');
    const [show, setShow] = useState(false);
    const [color, setColor] = useState('black')
    const style = { backgroundColor: 'white', borderRadius: '40px', color };

    
const navigate = useNavigate();
const user = getUser('user');
    useEffect(() => {
        if(!user){
            navigate('/login?login');
        
        }else{
            const getUserData = async () => {
                const uData = await userData()
                if(!uData.success){
                    setMessage("User Session has Expired. Please Login again!")
                    setColor('red');
                    setShow(true);
                   setTimeout(()=> {
                    deleteUser('user');
                    navigate('/login?login')
                   }, 4000);
                }
                
                 }
    
                getUserData()
        }
        }, [])

    return (
        <div className='home'>
        <Head/>
        <Row  style={{ margin:'auto', marginTop: ''}}>
             <h2 className="text-center"> Wallet Funding Options </h2>
            {/* <Col xs='12' sm='12' md='6' lg='6' xxs='12'  style={{ margin:'auto'}}> */}
            <Toast onClose={() => setShow(false)} show={show} delay={10000} autohide className='w-100'
        
                style={{ borderRadius: '40px', backgroundColor: 'white' }}
                >
                <Toast.Header className='  bg-light text-dark '  >
                    <small className='me-auto'>Message</small>
                </Toast.Header>

                <Toast.Body className= 'text-center'
                    style={style}>
                        <p><b> {message} </b></p>
                </Toast.Body>
            </Toast>
            <div  style={{ height: '50px', backgroundColor: 'whitesmoke',  borderRadius: '50px' }}>
            < >
            <Button className="float-start w-50" 
            style={{ borderRadius: '50px' }} 
             size='lg'
              onClick={() => {
                setActive('transfer')
                setStatus(true)
                
                }} variant='outline-dark' active={status}>
                    Transfer 
                </Button>

            <Button className="float-end w-50"
             style={{ borderRadius: '50px' }} 
            size="lg" 
            onClick={() => {
                setActive('card')
                setStatus(false)
                
                }} variant='outline-dark' active={!status}>
                    Other Method
                 </Button>  
                 </>
     </div> 
     {active === 'transfer'? 
            <Transfer/> : <CardOption/>
            }
            {/* </Col> */}
        </Row>
        </div>
    )
}

export default Fund;